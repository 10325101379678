import ActionTypes from '../constants/actions';

const initialState = {count: 0};

export default function setup(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.Setup.INCREMENT:
      return Object.assign({}, state, {
        count: state.count + 1
      });

    default:
      return state;
  }
}
